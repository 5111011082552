import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import React, { Fragment } from "react";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import ParagraphTypography from "../components/v2/Typography/ParagraphTypography";
import discoverVirtualSellingbg from "../images/v2/zoom/zoom-virtual-sellingbg.svg";
import zoomCrmbg from "../images/v2/zoom/zoom-crmbg.png";
import scaleOutReach from "../images/v2/zoom/scale-your-outreach.png";
import CheckIcon from "@material-ui/icons/Check";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import useWidth from "../hooks/useWidth";
import WhiteButton from "../components/v2/WhightButton";
import SEO from "../components/SEO";

const zoomStyle = makeStyles((theme) => ({
  zoomRoot: {
    background: `url(${discoverVirtualSellingbg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  zoomCrmSection: {
    background: `url(${zoomCrmbg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  scaleOutReachSection: {
    background: `url(${scaleOutReach})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  teamUP: {
    textAlign: "center",
  },
  img: {
    maxWidth: "100%",
    height: "auto",
  },
  listItemText: {
    "& *": {
      fontSize: 20,
      color: "#2e3f4f",
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
      },
    },
  },
  listItemIcon: {
    color: "#fff",
    fontSize: 30,
    backgroundColor: "#08a742",
    borderRadius: "50%",
    padding: "5px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      marginRight: theme.spacing(1.5),
    },
  },
}));

const zoom = () => {
  const classes = zoomStyle();
  const width = useWidth();

  const aboutZoom = [
    { title: "Easy to set up, use, and manage" },
    { title: "High-quality audio and video" },
    { title: "User-friendly interface" },
    { title: "Built-in collaboration features" },
  ];
  const zoomCRM = [
    {
      title:
        "Set up demos and client meetings instantly in Zoom, right from your sales CRM.",
    },
    {
      title:
        "Create the Zoom meeting link and send it to the invitees directly through your integrated email.",
    },
    {
      title:
        "Manage, edit, and update all your Zoom meetings without leaving the CRM.",
    },
    {
      title:
        "Update your activities list automatically with Zoom meeting details.",
    },
    {
      title:
        "Eliminate app switching and join Zoom meetings with a single click.",
    },
  ];
  return (
    <Fragment>
      <SEO
        canonical="/zoom"
        description="Schedule and manage Zoom meetings from within the CRM. Create face-to-face connections through video conferencing via CRM Zoom integration."
        keywords="crm zoom integration,Zoom integration,zoom crm,video conferencing"
        pathname="/zoom"
        title="Best Sales CRM with Zoom Meetings Integration | Pepper Cloud"
      />
      <Box className={classes.zoomRoot} py={8}>
        <Container>
          <Grid alignItems="center" container justify="center" spacing={7}>
            <Grid item lg={5} md={5} sm={12}>
              <Box className={classes.homeSliderText}>
                <HeaderTypography mt={0} mb={2} component="h1">
                  Discover virtual selling with Zoom integration
                </HeaderTypography>
                <ParagraphTypography>
                  Unlock the next level of remote selling with Pepper Cloud and
                  Zoom integration. Schedule and manage video meetings and
                  connect with your clients right from your CRM!
                </ParagraphTypography>
                <Box className={classes.getStartedBtn} mt={3}>
                  <Button
                    className={classes.customButton}
                    color="secondary"
                    component={Link}
                    size="large"
                    to="/contact"
                    variant="contained"
                  >
                    Get Started
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Hidden smDown>
              <Grid item lg={1} md={1}></Grid>
            </Hidden>
            <Grid
              component={Box}
              item
              lg={6}
              maxWidth="100%"
              md={6}
              paddingLeft="20px"
              sm={12}
            >
              <Box>
                <StaticImage
                  alt="zoom crm integration for video conferencing"
                  className={classes.img}
                  placeholder="blurred"
                  src="../images/v2/zoom/discover-virtual-selling.png"
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.teamUP} p={{ xs: 2, sm: 4, md: 6, lg: 8 }}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <HeaderTypography mb={3} mt={0}>
              Explore video conferencing with Zoom-integrated CRM
            </HeaderTypography>
            <ParagraphTypography>
              Create face-to-face connections and humanise your sales with Zoom
              CRM. Launch Zoom meetings right from your CRM and close deals
              faster.
            </ParagraphTypography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box pt={{ xs: 2, sm: 2, md: 2, lg: 6 }}>
              <StaticImage
                alt="Benefit with Zoom integrated CRM"
                className={classes.img}
                placeholder="blurred"
                src="../images/v2/zoom/team-up.png"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Divider light />
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        p={{ xs: 2, sm: 4, md: 6, lg: 8 }}
        textAlign="center"
      >
        <HeaderTypography mb={1} mt={0}>
          About Zoom
        </HeaderTypography>
        <ParagraphTypography maxWidth={947}>
          Zoom is a world-class cloud-based video conferencing service you can
          use to virtually meet with others - either by video, audio-only, or
          both, all while conducting live chats.
        </ParagraphTypography>
        <Box p={{ xs: 2, sm: 2, md: 2, lg: 4 }}>
          <StaticImage
            alt="About Zoom"
            className={classes.img}
            placeholder="blurred"
            src="../images/v2/zoom/about-zoom.png"
          />
        </Box>
        <Box container display="flex" justifyContent="center" maxWidth={800}>
          <Box>
            <Grid container>
              {aboutZoom.map((item, i) => (
                <Grid item key={i} lg={6} md={6} sm={6} xs={12}>
                  <ListItem alignItems="center" textAlign="center">
                    <ListItemIcon>
                      <CheckIcon className={classes.listItemIcon} />
                    </ListItemIcon>
                    <ListItemText className={classes.listItemText}>
                      {item.title}
                    </ListItemText>
                  </ListItem>
                </Grid>
              ))}
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box mt={4}>
                  <Button
                    className={classes.bookNowButton}
                    color="secondary"
                    component={Link}
                    size="large"
                    to="/contact"
                    variant="contained"
                  >
                    Get Started
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        className={
          width === "sm" || width === "xs" ? "" : classes.zoomCrmSection
        }
      >
        <Box component={Container}>
          <Box display="flex" justifyContent="center">
            <Box pb={3}>
              <Box display="flex" justifyContent="center">
                <Box
                  maxWidth={800}
                  pb={{ sm: 2, xs: 2 }[width] || 4}
                  pt={4}
                  textAlign="center"
                >
                  <HeaderTypography
                    alignItems="center"
                    component="h2"
                    display="flex"
                    fontWeight={600}
                    justifyContent="center"
                    lineHeight={1.2}
                    mb={3}
                  >
                    Zoom CRM integration to power up your sales communications
                  </HeaderTypography>
                  <ParagraphTypography
                    component="h3"
                    fontSize={18}
                    lineHeight={1.5}
                    mb={0}
                  >
                    Create face-to-face connections and humanise your sales with
                    Zoom CRM. Launch Zoom meetings right from your CRM and close
                    deals faster.
                  </ParagraphTypography>
                </Box>
              </Box>
              <Grid alignItems="center" container justify="center" spacing={2}>
                <Grid alignItems="center" item md={6} sm={12} xs={12}>
                  <Box pr={{ sm: 1, xs: 1 }[width] || 8}>
                    <List>
                      {zoomCRM.map((each) => (
                        <ListItem alignItems="center" key={each}>
                          <ListItemIcon>
                            <CheckIcon className={classes.listItemIcon} />
                          </ListItemIcon>
                          <ListItemText className={classes.listItemText}>
                            {each.title}
                          </ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <Box
                    display="flex"
                    justifyContent={
                      { sm: "center", xs: "center" }[width] || "flex-end"
                    }
                  >
                    <StaticImage
                      alt="Zoom meeting invite from CRM"
                      className={classes.cycleProcessImg}
                      placeholder="blurred"
                      src="../images/v2/zoom/zoom-crm-integration.png"
                    />
                  </Box>
                </Grid>
              </Grid>
              <br />
              <br />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        className={classes.scaleOutReachSection}
        px={2}
        py={{ sm: 10, xs: 8 }[width] || 12}
      >
        <HeaderTypography
          color="common.white"
          component="h2"
          fontSize={35}
          mt={0}
          textAlign="center"
        >
          Scale your outreach with video conferencing
        </HeaderTypography>

        <Box mt={6} textAlign="center">
          <WhiteButton
            color="primary"
            component={Link}
            size="large"
            to="/contact"
            variant="contained"
          >
            Let’s Do This
          </WhiteButton>
        </Box>
      </Box>
    </Fragment>
  );
};

export default zoom;
